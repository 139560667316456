/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import {
  useDCAPageModelStore,
  useClientEnvVarsStore,
  DCAPageModelState,
  DCAPageModelActions,
} from '@marriott/mi-store-utils';
import {
  findKeysWithMatchingValues,
  findKeysWithMatchingValuesAndObject,
  getDataFromPath,
  transformTargetModel,
} from '../lib/dynamicContentAssemblyUtils';
import { targetPayloadRequest } from '../lib/dynamicContentAssemblyUtils/targetSDKPayload';
import { dcaDynamicContent } from '@marriott/mi-merch-graphql';
import { useNextMiApolloClient } from '@marriott/mi-apollo-client-utils';
import createMappingModelJSON from '../lib/dynamicContentAssemblyUtils/responseMapping';
import operationSignatures from '../lib/dynamicContentAssemblyUtils/_constants/operationSignatures.json';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import isEmpty from 'lodash/isEmpty';
import { logger } from '@marriott/shared/mi-helper-utils';
import { useSession } from '../lib/session';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const transformJSON = require('json-map-transform');

declare global {
  interface Window {
    sessionData: any;
    mvpOffers?: {
      specialMessage?: string;
      rpcCode?: string;
      offerCode?: string;
      source?: string;
    };
    preQualShowOfferEvent: () => void;
    _satellite: Record<string, any>;
  }
}

// @todo - need to fill this out and have clients of this pass in a type for T
export type DCAModelData = {
  isTargetContentLoaded?: boolean;
  isTargetContentError?: boolean;
  metaData: Record<string, string>;
};

export const useDCADynamicModel = <T extends DCAModelData>(mboxParam?: string, cellCodes = '') => {
  const { log } = logger({})('useDCADynamicModel');
  const [compKey, setCompkey] = useState<string | undefined>('');
  const [isPrequal, setIsPrequal] = useState<boolean>(false);
  const [compModel, setCompModel] = useState<T | any>(() => ({} as T)); // @todo: remove the any
  const [requiredPath, setRequiredPath] = useState<string>('');
  const { pageModel, updateComponent } = useDCAPageModelStore(
    (state: DCAPageModelState & DCAPageModelActions) => state
  );
  const { datalayer, mvpOffers } = useSession();

  const updateWithOriginalContent = (compKey: string, isError: boolean) => {
    // Create a new object with isTargetContentLoaded property
    const transformerData = {
      ...compModel,
      isTargetContentLoaded: true,
      isTargetContentError: isError, // downstream components rely heavily on this, so will always be set to true
    };
    updateComponent(compKey, transformerData, requiredPath);
  };

  useEffect(() => {
    // fail safe condition if the preQualShowOfferEvent is not available on window
    if (typeof window?.preQualShowOfferEvent === 'function' && isPrequal) {
      window?.preQualShowOfferEvent();
    }
  }, [isPrequal]);

  useEffect(() => {
    if (!isEmpty(pageModel) && mboxParam) {
      const path = findKeysWithMatchingValuesAndObject(pageModel, mboxParam);
      setRequiredPath(path);
      const data = getDataFromPath(pageModel, path);
      setCompModel(data);
      if (!compKey) {
        const key = findKeysWithMatchingValues(pageModel, mboxParam);
        setCompkey(key);
      }
    }
  }, [pageModel, mboxParam]);

  useEffect(() => {
    if (!mboxParam) {
      log.info('mboxParam is empty');
      return;
    }
    if (isEmpty(compModel) || compModel['isTargetContentLoaded']) {
      log.info('compModel is empty or previously loaded');
      return;
    }
    if (isEmpty(datalayer)) {
      log.info('datalayer is empty');
      return;
    }
    if (!compKey) {
      log.info('compKey is empty');
      return;
    }
    if (!AuthoringUtils.isInEditor() && AuthoringUtils.isPreviewMode()) {
      log.info('AuthoringUtils.isInEditor() && AuthoringUtils.isPreviewMode()');
      return;
    }

    const options = targetPayloadRequest(
      {
        locale:
          window?.sessionData?.locale?.replace('-', '_') ||
          window?._satellite?.['getVar']('brwsrLang') ||
          datalayer?.['brwsrLang'] ||
          'en_US',
        mboxName: mboxParam,
        propertyToken:
          process.env['TARGET_PROPERTY_TOKEN'] ||
          useClientEnvVarsStore.getState().envVarsObject['TARGET_PROPERTY_TOKEN'],
        isACDL: useClientEnvVarsStore.getState().envVarsObject?.['NEXT_PUBLIC_ENABLE_WEBSDK'],
        cellCodes: cellCodes,
      },
      datalayer,
      mvpOffers
    );
    (async () => {
      try {
        const MiApolloClient = useNextMiApolloClient(
          operationSignatures,
          useClientEnvVarsStore.getState().envVarsObject['DEPLOYED_ENV_TYPE']
        );
        const { data, errors } = await MiApolloClient.query({
          query: dcaDynamicContent,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
          variables: {
            input: options,
          },
          context: {
            headers: {},
          },
        });
        if (errors && errors.length) {
          throw new Error('DCA call error');
        }
        const resp = data.dynamicContentByChannelAndMboxes.mboxes[0];
        if (!resp) {
          log.warn(`no mboxes found for - ${mboxParam}`);
          updateWithOriginalContent(compKey, true);
        }
        let transformerData;
        if (resp) {
          const mappingModelJSON = createMappingModelJSON();
          // Mapping should happen at AEM level, need to check with AEM team on why there is a gap
          const mappedResponse = transformJSON(resp, mappingModelJSON);
          transformerData = transformTargetModel(compModel, mappedResponse);
          if (transformerData) {
            // Create a new object with isTargetContentLoaded property
            const updatedTransformerData = { ...transformerData, isTargetContentLoaded: true };
            updateComponent(compKey, updatedTransformerData, requiredPath);
            if (String(resp?.metadata?.prequal) === 'true') {
              setIsPrequal(true);
            }
          }
        }
      } catch (error) {
        log.error('Error fetching DCA data, using original content', error);
        updateWithOriginalContent(compKey, true);
      }
    })();
  }, [mboxParam, updateComponent, compKey, compModel, datalayer, mvpOffers]);
  return {
    targetData: compModel,
  };
};
